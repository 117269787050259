import { useState, useEffect } from 'react'
import axios from 'axios'
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google'

import { useRecoilState } from 'recoil'
import { walletState } from '../../atoms/WalletAtom'
import { emailState } from '../../atoms/EmailAtom'
import { artistDataState } from '../../atoms/artistDataAtom'
import { pageState } from '../../atoms/PageAtom'
import { sessionStatusState } from '../../atoms/SessionStatusAtom'
import { isLoginState } from '../../atoms/IsLoginAtom'
import { ZERO_ADDRESS } from '../../constants'

type Artist = {
  public_id: string
  display_name: string
  comment: string
  gift_detail: string
  icon_uri: string
}

const OAuthGoogleLogin = () => {
  const [walletAddress, setWalletAddress] = useRecoilState(walletState)
  const [email, setEmail] = useRecoilState(emailState)
  const [, setArtistData] = useRecoilState<Artist>(artistDataState)
  const [, setPage] = useRecoilState(pageState)
  const [isLogin, setIsLogin] = useRecoilState<boolean | null>(isLoginState)
  const [sessionStatus, setSessionStatus] =
    useRecoilState<SessionStatus>(sessionStatusState)

  useEffect(() => {
    if (walletAddress) {
      session('')
    } else {
      checkSession()
    }
  }, [])

  const [sessionNow, setSessionNow] = useState(false)
  const checkSession = () => {
    axios({
      method: 'GET',
      url: `${
        import.meta.env.VITE_API_BASE_URI
      }/api/v1/google_oauth/check_session`,
    })
      .then((response) => {
        const isSession = response.data.is_session
        if (isSession) {
          if (response.data.is_artist && response.data.artist) {
            setArtistData(response.data.artist)
          } else {
            setArtistData({} as Artist)
          }
          setWalletAddress(response.data.wallet_address)
          setEmail(response.data.email)

          setSessionStatus({
            walletConnected: response.data.wallet_address !== ZERO_ADDRESS,
            googleConnected: response.data.email,
            googleTokenValidityStatus:
              response.data.google_token_validity_status,
          })
          setIsLogin(true)
        } else {
          setIsLogin(false)
        }
      })
      .catch((error) => {
        console.error('error', error)
        setSessionNow(false)
      })
  }

  const session = (credential: string | undefined) => {
    if (sessionNow || !credential) return setIsLogin(false)

    setSessionNow(true)

    axios({
      method: 'POST',
      url: `${import.meta.env.VITE_API_BASE_URI}/api/v1/google_oauth/session`,
      params: {
        credential: credential,
      },
    })
      .then((response) => {
        const message = response.data.message
        if (message !== 'success') return errorHandling(message)

        if (response.data.is_artist && response.data.artist) {
          setArtistData(response.data.artist)
        } else {
          setArtistData({} as Artist)
        }

        setWalletAddress(response.data.wallet_address)
        setEmail(response.data.email)
        setSessionStatus({
          walletConnected: sessionStatus.walletConnected,
          googleConnected: true,
          googleTokenValidityStatus: response.data.google_token_validity_status,
        })
        setSessionNow(false)
        setIsLogin(true)
      })
      .catch((error) => {
        console.error('error', error)
        setSessionNow(false)
      })
  }

  const errorHandling = (message: string) => {
    if (message.indexOf('other address registered') !== -1) {
      alert('ログイン中のウォレットには既に他のアドレスが連携されています')
    } else if (message.indexOf('other user logged in') !== -1) {
      alert('既に他のユーザーがログインしています')
    } else {
      alert('ログイン処理に失敗しました')
    }
    setSessionNow(false)
  }

  return (
    <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID}>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          session(credentialResponse.credential)
        }}
        onError={() => {
          console.error('Login Failed')
        }}
        size="medium"
        shape="pill"
      />
    </GoogleOAuthProvider>
  )
}

export default OAuthGoogleLogin
