import { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { ethers } from 'ethers'
import axios, { AxiosResponse, AxiosError } from 'axios'

import { useRecoilState } from 'recoil'
import { walletState } from '../../atoms/WalletAtom'
import { emailState } from '../../atoms/EmailAtom'
import { artistDataState } from '../../atoms/artistDataAtom'
import { notificationsState } from '../../atoms/NotificationsAtom'
import { sessionStatusState } from '../../atoms/SessionStatusAtom'
import { isLoginState } from '../../atoms/IsLoginAtom'

import { ZERO_ADDRESS } from '../../constants'

import WalletConnectV2 from './WalletConnectV2'

type Props = {
  setWeb3: Dispatch<SetStateAction<Web3>>
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  buttonText?: string
}

type Artist = {
  public_id: string
  display_name: string
  comment: string
  gift_detail: string
  icon_uri: string
}

interface Window {
  location: Location
}
declare let window: Window

function ConnectWalletButton(props: Props) {
  const [provider, setProvider] = useState<
    ethers.providers.Web3Provider | undefined
  >(undefined)
  const [isSessionLoading, setIsSessionLoading] = useState(false)
  const [verifiedWalletAddress, setVerifiedWalletAddress] = useState('')
  const [walletAddress, setWalletAddress] = useRecoilState(walletState)
  const [email, setEmail] = useRecoilState(emailState)
  const [isLogin, setIsLogin] = useRecoilState<boolean | null>(isLoginState)
  const [sessionStatus, setSessionStatus] =
    useRecoilState<SessionStatus>(sessionStatusState)

  const [, setArtistData] = useRecoilState<Artist>(artistDataState)
  const [, setNotifications] = useRecoilState(notificationsState)

  // Ethereum ==================
  // const networkId = '0x1'
  // const networkName = 'homestead'
  // Goerli ====================
  // const networkId = '0x5'
  // const networkName = 'goerli'
  // Polygon ===================
  // const networkId = '0x89'
  // const networkName = 'matic'
  // Matic Testnet Mumbai ======
  // const networkId = '0x13881'
  // const networkName = 'mumbai'
  // ===========================

  useEffect(() => {
    if (!verifiedWalletAddress) return

    setWalletAddress(verifiedWalletAddress)
    setIsLogin(true)
    setVerifiedWalletAddress('')
  }, [verifiedWalletAddress])

  useEffect(() => {
    if (!walletAddress) return
    fetchNotifications()
  }, [walletAddress])

  useEffect(() => {
    if (!provider) return

    signInWithEthereum()
  }, [provider])

  const connectWallet = async (
    provider: ethers.providers.ExternalProvider | undefined
  ) => {
    if (!provider) {
      setIsSessionLoading(false)
      return
    }

    if (isSessionLoading) {
      alert('現在ウォレット接続処理中です。')
      return
    }
    setIsSessionLoading(true)

    try {
      const ethersProvider = new ethers.providers.Web3Provider(provider)
      const signer = ethersProvider.getSigner()
      setProvider(ethersProvider)

      props.setWeb3({ provider: ethersProvider, signer: signer })

      const connectedNetwork = await ethersProvider.getNetwork()
      console.log(`connected network: ${connectedNetwork.name}`)

      // Success
      setIsSessionLoading(false)
    } catch (error) {
      console.error('Error connecting wallet', error)
      setIsSessionLoading(false) // Ensure loading state is cleared on error
      alert('接続に失敗しました')
    }
  }

  const verifyWallet = async (address: string) => {
    await axios({
      method: 'POST',
      url: `${import.meta.env.VITE_API_BASE_URI}/api/v1/siwe/verify`,
      params: {
        address: address,
      },
    })
      .then(
        (
          response: AxiosResponse<{
            is_artist: boolean
            artist: Artist
            email: string
            google_token_validity_status: boolean | undefined | null
          }>
        ) => {
          if (response.data.is_artist && response.data.artist) {
            setArtistData(response.data.artist)
          } else {
            setArtistData({} as Artist)
          }
          setWalletAddress(address)
          setSessionStatus({
            walletConnected: true,
            googleConnected: response.data.email ? true : false,
            googleTokenValidityStatus:
              response.data.google_token_validity_status,
          })
          setEmail(response.data.email)
          setIsLogin(true)
          setIsSessionLoading(false)
        }
      )
      .catch((error: AxiosError<{ error: string }>) => {
        console.error('verify error', error)
        signInWithEthereum()
        setIsSessionLoading(false)
      })
  }

  const getMessage = async (address: string) => {
    if (!provider) {
      setIsSessionLoading(false)
      return alert('接続に失敗しました（provider undefined)')
    }

    const { chainId } = await provider.getNetwork()

    return await axios({
      method: 'POST',
      url: `${import.meta.env.VITE_API_BASE_URI}/api/v1/siwe/message`,
      params: {
        chainId: chainId,
        address: address,
      },
    })
      .then((response: AxiosResponse<string>) => {
        return response.data
      })
      .catch((error: AxiosError<{ error: string }>) => {
        console.error('error', error)
        setIsSessionLoading(false)
      })
  }

  const signInWithEthereum = async () => {
    if (!provider) {
      setIsSessionLoading(false)
      return alert('接続に失敗しました（provider undefined)')
    }

    const address = await provider.getSigner().getAddress()

    if (
      walletAddress === ZERO_ADDRESS &&
      sessionStatus.googleConnected &&
      !sessionStatus.walletConnected
    ) {
      if (
        !confirm(
          `現在Googleアカウントでサインイン中です。\nウォレットを接続するとGoogleアカウントは自動的にサインアウトされますがよろしいですか？\n接続するウォレットアドレス：${address}`
        )
      ) {
        setIsSessionLoading(false)
        return alert('ウォレット接続をキャンセルしました')
      }
    }

    const message = await getMessage(address)

    if (!message) {
      alert('no message')
      setIsSessionLoading(false)
      return
    }

    let ens
    // try {
    //   ens = await provider.lookupAddress(address)
    // } catch (error) {
    //   console.error(error)
    // }

    const signature = await provider
      .getSigner()
      .signMessage(message)
      .catch(() => {
        setIsSessionLoading(false)
      })

    try {
      await axios({
        method: 'POST',
        url: `${import.meta.env.VITE_API_BASE_URI}/api/v1/siwe/sign_in`,
        params: {
          signature: signature,
          ens: ens,
          address: address,
        },
      }).then(
        async (
          response: AxiosResponse<{
            email: string
            google_token_validity_status: boolean | undefined | null
          }>
        ) => {
          console.log('ウォレット認証に成功しました')
          setVerifiedWalletAddress(address)
          if (!walletAddress) await verifyWallet(address)
          setIsSessionLoading(false)

          setSessionStatus({
            walletConnected: true,
            googleConnected: response.data.email ? true : false,
            googleTokenValidityStatus:
              response.data.google_token_validity_status,
          })
          setEmail(response.data.email)
        }
      )
    } catch (error) {
      console.error('error', error)
      setIsSessionLoading(false)
    }
  }

  // ウォレットに紐づいた通知を取得する
  const fetchNotifications = () => {
    axios({
      method: 'GET',
      url: `${import.meta.env.VITE_API_BASE_URI}/api/v1/notifications`,
      params: {
        address: walletAddress,
      },
    })
      .then((response) => {
        setNotifications(response.data.notifications)
      })
      .catch((error) => {
        console.error('error', error)
      })
  }

  return (
    <WalletConnectV2
      setIsModalOpen={props.setIsModalOpen}
      connectWalletFunc={connectWallet}
      isSessionLoading={isSessionLoading}
      isLogin={isLogin}
      buttonText={props.buttonText}
    />
  )
}

export default ConnectWalletButton
