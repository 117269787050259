import { useEffect, Dispatch, SetStateAction } from 'react'
import { ethers } from 'ethers'
import axios from 'axios'

import { useRecoilValue } from 'recoil'
import { walletState } from '../../atoms/WalletAtom'

import { useTranslation } from 'react-i18next'

import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from '@web3modal/ethers5/react'

type Props = {
  isSessionLoading: boolean
  isLogin: boolean | null
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  connectWalletFunc: (
    provider: ethers.providers.ExternalProvider | undefined
  ) => void
  buttonText?: string | null
}

function WalletConnectV2(props: Props) {
  const { t } = useTranslation()

  // 4. Use modal hook
  const { open } = useWeb3Modal()

  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()

  const walletAddress = useRecoilValue<string>(walletState)

  useEffect(() => {
    // 非同期処理を実行するための内部関数を定義
    const fetchData = async () => {
      if (!address || !walletProvider || props.isLogin === null) return
      if (
        walletAddress &&
        walletAddress.toLowerCase() === address.toLowerCase()
      )
        return

      // @userがログインしている場合は、ログインしているアドレスを表示する
      const check_address = await check_at_user()
      if (check_address.toLowerCase() === address?.toLowerCase()) return

      props.connectWalletFunc(walletProvider)
    }

    // 定義した非同期関数を呼び出す
    fetchData()
  }, [address, walletProvider, props.isLogin])

  useEffect(() => {
    if (!walletProvider) return

    // チェーン変更を検出
    walletProvider.on('chainChanged', (chainId: number) => {
      props.connectWalletFunc(walletProvider)
      return
    })

    // アカウント変更を検出
    walletProvider.on('accountsChanged', (accounts: string[]) => {
      props.connectWalletFunc(walletProvider)
      return
    })
  }, [walletProvider])

  const accountOmit = (text: string, len: number, ellipsis: string) =>
    text.length >= len ? text.slice(0, len - ellipsis.length) + ellipsis : text

  // @userがログインしている場合は、ログインしているアドレスを表示する
  const check_at_user = async (): Promise<string> => {
    const returnAddress = await axios({
      method: 'GET',
      url: `${import.meta.env.VITE_API_BASE_URI}/api/v1/users/check_at_user`,
      params: {
        address: address,
      },
    })
      .then((response) => {
        return response.data.address
      })
      .catch((error) => {
        console.error('error', error)
        return ''
      })
    return returnAddress
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <button
        className="wallet-button"
        onClick={() => {
          open()
          props.setIsModalOpen(false)
        }}
      >
        {props.isSessionLoading
          ? 'connecting'
          : address
          ? accountOmit(address, 10, '...')
          : props.buttonText || 'Connect Wallet'}
      </button>
      {walletProvider && (
        <button
          className="reconnect-button"
          onClick={() => props.connectWalletFunc(walletProvider)}
        >
          - {t('components.modal.reconnect')} -
        </button>
      )}
    </div>
  )
}
export default WalletConnectV2
